<template>
  <div>
    <head-slot>
      <title>Afghanistan Cricket Board | Dashboard</title>
    </head-slot>

  </div>
</template>
<script>

export default {
  data() {
    return {
      user: '',
    }
  },

  methods: {


  }
}
</script>